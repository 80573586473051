.w-pc-store-screen-2{
    height: 35vh;
    width: 100%;
    background-color: #343429;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.w-pc-store-screen-2>h3{
    font-size: 39px;
    letter-spacing: 1px;
    color: #fff;
}

.w-mob-store-screen-2 {
    height: 35vh;
    width: 100%;
    background-color: #343429;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-mob-store-screen-2>h3 {
    font-size: 20px;
    letter-spacing: 1px;
    color: #fff;
}

.w-tab-store-screen-2 {
    height: 35vh;
    width: 100%;
    background-color: #343429;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-tab-store-screen-2>h3 {
    font-size: 39px;
    letter-spacing: 1px;
    color: #fff;
}