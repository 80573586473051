.w-pc-accessory-2{
    position: absolute;
    padding: 0;
    margin: 0;
    top: 20vh;
    /* border: 1px dashed white; */
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    /* background-color: red; */
};
.w-pc-accessory-2>.wrapper{
    position: relative;
}

.w-pc-accessory-2>.wrapper>.worm {
    padding: 0;
    margin: 0;
    margin-bottom: 10vh;
    transition: 1.3s ease;
}

.w-pc-accessory-2>.wrapper>.worm-orange {
    height: 6.5vw;
    width: 50vw;
    background-color: #FF914D;
    border-radius: 90px;
    position: absolute;
    /* left: -40vh; */

}

.w-pc-accessory-2>.wrapper>.worm-1{
    top: 42vh;
    left: -32vw;
    transform: rotate(-30deg) translate(-40vw, 0);
}
.w-pc-accessory-2>.wrapper>.worm-1.onSlideShowUp{
    transform: rotate(-30deg) translate(-11vw, 0);
}
.w-pc-accessory-2>.wrapper>.worm-2 {
    top: 60vh;
    left: -32vw;
    transform: rotate(-30deg) translate(-20vw, 0);;
}
.w-pc-accessory-2>.wrapper>.worm-2.onSlideShowUp {
    transform: rotate(-30deg) translate(1vw, 0);
}
