.w-pc-app-navbar {
    padding: 0;
    margin: 0;
    height: 10vh;
    width: 100%;
    /* box-shadow: 0px 3px 3px rgba(128, 128, 128, 0.221); */

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: fixed;
    z-index: 9000;
    color: #fff;
    box-sizing: border-box;
    /* border: 0.5px solid red; */
}

.w-pc-app-navbar>div {
    padding: 0;
    margin: 0;
    /* border: 0.5px solid gray; */
}
.w-pc-app-navbar>.anchors-group-3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.w-pc-app-navbar>.anchors-group-3>*{
    margin-inline: 20px;
    cursor: pointer;
}





/* mobile */
.w-mob-app-navbar {
    padding: 0;
    margin: 0;
    height: 10vh;
    width: 100%;
    /* box-shadow: 0px 3px 3px rgba(128, 128, 128, 0.221); */

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: fixed;
    z-index: 9000;
    color: #fff;
    box-sizing: border-box;
    /* border: 0.5px solid red; */
}

.w-mob-app-navbar>div {
    padding: 0;
    margin: 0;
    /* border: 0.5px solid gray; */
}

.w-mob-app-navbar>.anchors-group-3 {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    box-sizing: border-box;
    /* border: 1px solid black; */
}

.w-mob-app-navbar>.anchors-group-3>* {
    /* margin-inline: 20px; */
    cursor: pointer;
}

.w-mob-app-navbar>.anchors-group-3>.burger{
    height: 8vh;
    width: 8vh;
    /* border: 1px solid black; */
    position: relative;
}


.w-mob-app-navbar>.anchors-group-3>.burger>.burger-layer {
    position: absolute;
    height: 1.2vh;
    width: 13vw;
    background-color: #343429;
    transition: 0.5s;
}
.w-mob-app-navbar>.anchors-group-3>.burger-darker>.burger-layer {
    position: absolute;
    height: 1.2vh;
    width: 13vw;
    background-color: #fff;
}
.w-mob-app-navbar>.anchors-group-3>.burger>.layer-1{
    top:1vh;
}
.w-mob-app-navbar>.anchors-group-3>.burger>.layer-2 {
    top:2.8vh;
}
.w-mob-app-navbar>.anchors-group-3>.burger>.layer-3 {
    top: 4.6vh;
}


/* tab */
.w-tab-app-navbar {
    padding: 0;
    margin: 0;
    height: 10vh;
    width: 100%;
    /* box-shadow: 0px 3px 3px rgba(128, 128, 128, 0.221); */

    display: grid;
    grid-template-columns: 1fr 0.7fr 1.3fr;
    position: fixed;
    z-index: 9000;
    color: #fff;
    box-sizing: border-box;
    /* border: 0.5px solid red; */
}

.w-tab-app-navbar>div {
    padding: 0;
    margin: 0;
    /* border: 0.5px solid gray; */
}

.w-tab-app-navbar>.anchors-group-3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 1vw;
    /* border: 1px solid red; */
}

.w-tab-app-navbar>.anchors-group-3>* {
    margin-inline: 1.6vw;
    cursor: pointer;
}

.w-tab-app-navbar>.anchors-group-3>.burger {
    height: 8vh;
    width: 8vh;
    /* border: 1px solid black; */
    position: relative;
}

.w-tab-app-navbar>.anchors-group-3>.burger>.burger-layer {
    position: absolute;
    height: 1.5vh;
    width: 8vh;
    background-color: #343429;
}

.w-tab-app-navbar>.anchors-group-3>.burger>.layer-1 {
    top: 0vh;
}

.w-tab-app-navbar>.anchors-group-3>.burger>.layer-2 {
    top: 2.25vh;
}

.w-tab-app-navbar>.anchors-group-3>.burger>.layer-3 {
    top: 4.5vh;
}



.burger-collapsed{
    position: fixed;
    top: 0;
    width: 100%;
    height: 27vh;
    background-color: #000000;
    transition: 0.5s;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px dashed #fff; */
}
.burger-collapsed>img {
    height: 13px;
    filter: invert(90%);
    align-self: end;
    padding-inline: 4.9vw;
    padding-top: 0vh;
    padding-bottom: 3vh;
}
.burger-collapsed>p{
    margin-block: 5px;
}
.slide-hide{
    transform: translateY(-100%);
}