.w-pc-artist-screen-1{
    position: relative;
    padding:0;
    margin:0;
    /* padding-top: 10vh; */
    background-image: url('../../assets/home/take-8.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    box-sizing: border-box;
    
    height: 100vh;
    width: 100%;
}
.w-pc-artist-screen-1>.wrapper{
    padding: 10vh;
    box-sizing: border-box;
    background-color: rgba(58, 56, 56, 0.151);
    /* opacity: 30%; */
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x:hidden;
}
.w-pc-artist-screen-1>.wrapper>p{
    letter-spacing: 1px;
    font-size: 10vh;
    font-weight: 500;
    transition: 1.5s ease;
    opacity: 0%;
}
.w-pc-artist-screen-1>.wrapper>h1{
    letter-spacing: 5px;
    font-size: 20vh;
    z-index: 5;
    text-align: center;
    transition: 1.5s ease;
    opacity: 0%;
}
.w-pc-artist-screen-1>.wrapper.onTimeOutAnimation>h1 {
    opacity: 100%;
    /* font-size: 20vh; */
}
.w-pc-artist-screen-1>.wrapper.onTimeOutAnimation>p {
    opacity: 100%;
    /* font-size: 10vh; */
}

/* mobile */
.w-mob-artist-screen-1 {
    position: relative;
    padding: 0;
    margin: 0;
    /* padding-top: 10vh; */
    background-image: url('../../assets/home/take-8.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    box-sizing: border-box;

    height: 100vh;
    width: 100%;
}

.w-mob-artist-screen-1>.wrapper {
    padding: 10vh;
    box-sizing: border-box;
    background-color: rgba(58, 56, 56, 0.151);
    /* opacity: 30%; */
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.w-mob-artist-screen-1>.wrapper>p {
    letter-spacing: 1px;
    font-size: 4vh;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3vh;
    z-index: 5;
}

.w-mob-artist-screen-1>.wrapper>h1 {
    letter-spacing: 1px;
    font-size: 12vh;
    text-align: center;
    line-height: 12vh;
    z-index: 5;
}


/* tab */
.w-tab-artist-screen-1 {
    position: relative;
    padding: 0;
    margin: 0;
    /* padding-top: 10vh; */
    background-image: url('../../assets/home/take-8.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    box-sizing: border-box;

    height: 100vh;
    width: 100%;
}

.w-tab-artist-screen-1>.wrapper {
    padding: 10vh;
    box-sizing: border-box;
    background-color: rgba(58, 56, 56, 0.151);
    /* opacity: 30%; */
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.w-tab-artist-screen-1>.wrapper>p {
    letter-spacing: 1px;
    font-size: 4vh;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3vh;
}

.w-tab-artist-screen-1>.wrapper>h1 {
    letter-spacing: 1px;
    font-size: 12vh;
    text-align: center;
    line-height: 12vh;
}