.w-pc-screen3accessory{
    position: absolute;
    width: 100%;
    /* border: 1px dashed pink; */
    overflow: hidden;
    top: 0
}
.w-pc-screen3accessory>.wrapper{
    position: relative;
    width: 100vw;
    height: 100%;
    /* border: 1px solid yellow; */
}

.w-pc-screen3accessory>.wrapper>.triangle{
    position: absolute;
    transition: 2s;
    top: 0;
    left: 0;
    clip-path: polygon(600px 40px, 0px 30px, 0px 320px);
    background-color: #6C6D56;
    height: 100%;
    width: 100%;
    transform: translate(0px, -20px);
    z-index: 1;
}
.w-pc-screen3accessory>.wrapper>.triangle-2 {
    position: absolute;
    transition: 2s;
    bottom: 0%;
    right: -80vw;
    clip-path: polygon(600px 0px, 0px 30px, 0px 320px);
    background-color: #6C6D56;
    height: 100%;
    width: 100%;
    transform: translate(0px, 0px) rotate(-40deg);
    z-index: 1;
}


